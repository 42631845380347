import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet semicircular de fins a 15 cm de llarg, de 5 a 7 cm d’amplada i 1 cm de gruix, anual, sèssil. La superfície superior és molt peluda, amb pèls curts i gruixuts disposats en cercles més o manco concèntrics, amb color variable que va del marró al terrós, dominant el marró groguenc. L’himeni està format per tubs i porus de color marró un poc groguenc en els exemplars joves i més fosc en els vells. Les espores blanques en massa, llises, cilíndriques, de 7-14 x 3-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      